<script lang="ts" setup>
import { useTheme } from 'vuetify';

onMounted(() => {
  const theme = useTheme();

  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    theme.global.name.value = 'dark';
  } else {
    theme.global.name.value = 'light';
  }
});
</script>

<template>
  <v-layout>
    <NuxtPage />
    <!-- <NuxtPwaManifest /> -->
    <Snackbar />
  </v-layout>
</template>
